<template>
  <b-row>

    <!--    UPDATE EMAIL     -->
    <b-col cols="6">
      <b-form-group
        :label="$t('settings.user_email')"
        label-for="defaultLabel"
      >
        <b-form-input
          v-model="adminEmail"
          id="defaultLabel"
          size="lg"
          :placeholder="$t('settings.user_email')"
        />
      </b-form-group>
    </b-col>

    <!--    UPDATE PROJECT NAME     -->
    <b-col cols="6">
      <b-form-group
        :label="$t('settings.project_name')"
        label-for="defaultLabel"
      >
        <b-form-input
          v-model="projectName"
          id="defaultLabel"
          size="lg"
          :placeholder="$t('settings.project_name')"
        />
      </b-form-group>
    </b-col>

    <!--    FAVICON     -->
    <b-col cols="6">
      <label>FAVICON</label>
      <VueFileAgent
        ref="vueFileAgentFavicon"
        :uploadUrl="uploadUrlFavicon"
        v-model="favicon"
        :deletable="true"
        :accept="'image/*,.zip'"
        :maxSize="'2MB'"
        :maxFiles="1"
        :helpText="$t('reusable.file_upload_text')"
        @select="filesSelectedFavicon($event)"
        @beforedelete="onBeforeDeleteFavicon($event)"
        @delete="fileDeletedFavicon($event)"
      >

      </VueFileAgent>
    </b-col>

    <!--    LOGO     -->
    <b-col cols="6">

      <label>LOGO</label>
      <VueFileAgent
        ref="vueFileAgentLogo"
        :uploadUrl="uploadUrlLogo"
        v-model="logo"
        :deletable="true"
        :accept="'image/*,.zip'"
        :maxSize="'2MB'"
        :maxFiles="1"
        :helpText="$t('reusable.file_upload_text')"
        @select="filesSelectedLogo($event)"
        @beforedelete="onBeforeDeleteLogo($event)"
        @delete="fileDeletedLogo($event)"
      >

      </VueFileAgent>
    </b-col>

    <!--  SAVE BTN  -->
    <b-col
      cols="2"
      class="mt-2"
    >
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="success"
        @click="updateSystemInfo"
      >
        {{ $t('settings.save_btn') }}
      </b-button>
    </b-col>

  </b-row>
</template>

<script>
import {
  BCol,
  BFormGroup,
  BFormInput,
  BRow,
  BButton
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import api from '@/services/api'
import Compressor from 'compressorjs'

export default {
  name: 'Settings',
  components: {
    BCol,
    BFormGroup,
    BFormInput,
    BRow,
    BButton
  },
  directives: {
    Ripple
  },

  data() {
    return {
      adminEmail: '',
      projectName: '',
      favicon: [],
      logo: [],
      fileRecordsFavicon: [],
      uploadUrlFavicon: '',
      uploadHeadersFavicon: { 'X-Test-Header': 'vue-file-agent' },
      fileRecordsForUploadFavicon: [],

      fileRecordsLogo: [],
      uploadUrlLogo: '',
      uploadHeadersLogo: { 'X-Test-Header': 'vue-file-agent' },
      fileRecordsForUploadLogo: [],
    }
  },

  async created() {
    await this.getSystemInfo()
  },

  methods: {

    async getSystemInfo() {
      await api.system.fetchSystemInfo()
        .then(res => {
          this.adminEmail = res.data.admin_email
          this.projectName = res.data.project_name
          let favicon = res.data.favicon
          this.favicon = [...this.favicon, {
            name: 'image.jpg',
            size: 0,
            type: 'image/jpg',
            url: favicon,
            src: favicon
          }]
          let logo = res.data.logo
          this.logo = [...this.logo, {
            name: 'image.jpg',
            size: 0,
            type: 'image/jpg',
            url: logo,
            src: logo
          }]
        })
        .catch((error) => {
          console.log(error)
        })
    },

    async updateSystemInfo() {
      const formData = new FormData()
      formData.append('project_name', this.projectName)
      formData.append('admin_email', this.adminEmail)
      formData.append('logo', this.logo[0].file)
      formData.append('favicon', this.favicon[0].file)
      await api.system.updateSystemInfo(formData)
        .then(res => {
          console.log(res)
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          window.location.reload()
        })
    },

    srcToFile(src, fileName, mimeType) {
      const token = localStorage.getItem('accessToken')
      return (fetch(src, {
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Authorization': token
            }
          }
        )
          .then(function (res) {
            return res.arrayBuffer()
          })
          .then(function (buf) {
            return new File([buf], fileName, { type: mimeType })
          })
      )
    },

    deleteUploadedFileFavicon: function (fileRecord) {
      api.system.deleteProductFile(this.favicon.indexOf(fileRecord))
      this.$refs.vueFileAgentFavicon.deleteUpload(this.uploadUrlFavicon, this.uploadHeadersFavicon, fileRecord)
    },

    filesSelectedFavicon: function (fileRecordsNewlySelected) {
      let validFileRecords = fileRecordsNewlySelected.filter((fileRecord) => !fileRecord.error)
      this.fileRecordsForUploadFavicon = this.fileRecordsForUploadFavicon.concat(validFileRecords)
    },

    onBeforeDeleteFavicon: function (fileRecord) {
      let i = this.fileRecordsForUploadFavicon.indexOf(fileRecord)
      if (i !== -1) {
        this.fileRecordsForUploadFavicon.splice(i, 1)
        this.deleteUploadedFileFavicon(fileRecord)
      } else {
        this.$refs.vueFileAgentFavicon.deleteFileRecord(fileRecord) // will trigger 'delete' event
      }
    },

    async fileDeletedFavicon(fileRecord) {
      const deleteFileRecord = this.favicon.findIndex(image => image.url === fileRecord.url)
      await api.system.deleteProductFile(deleteFileRecord)
      await this.$refs.vueFileAgentFavicon.deleteUpload(this.uploadUrlFavicon, this.uploadHeadersFavicon, fileRecord)
      let i = this.fileRecordsForUploadFavicon.indexOf(fileRecord)
      if (i !== -1) {
        this.fileRecordsForUploadFavicon.splice(i, 1)
      }
    },

    deleteUploadedFileLogo: function (fileRecord) {
      api.system.deleteProductFile(this.logo.indexOf(fileRecord))
      this.$refs.vueFileAgentFavicon.deleteUpload(this.uploadUrlLogo, this.uploadHeadersLogo, fileRecord)
    },

    filesSelectedLogo: function (fileRecordsNewlySelected) {
      let validFileRecords = fileRecordsNewlySelected.filter((fileRecord) => !fileRecord.error)
      this.fileRecordsForUploadLogo = this.fileRecordsForUploadLogo.concat(validFileRecords)
    },

    onBeforeDeleteLogo: function (fileRecord) {
      let i = this.fileRecordsForUploadLogo.indexOf(fileRecord)
      if (i !== -1) {
        this.fileRecordsForUploadLogo.splice(i, 1)
        this.deleteUploadedFileLogo(fileRecord)
      } else {
        this.$refs.vueFileAgentLogo.deleteFileRecord(fileRecord) // will trigger 'delete' event
      }
    },

    async fileDeletedLogo(fileRecord) {
      const deleteFileRecord = this.logo.findIndex(image => image.url === fileRecord.url)
      await api.system.deleteProductFile(deleteFileRecord)
      await this.$refs.vueFileAgentLogo.deleteUpload(this.uploadUrlLogo, this.uploadHeadersLogo, fileRecord)
      let i = this.fileRecordsForUploadLogo.indexOf(fileRecord)
      if (i !== -1) {
        this.fileRecordsForUploadLogo.splice(i, 1)
      }
    },

    compressImage(file) {
      return new Compressor(file, {
        maxWidth: 1200,
        quality: 0.8,
        convertSize: 5000000,
        success: (result) => {
          return new File([result], result.name, {
            type: result.type,
            lastModified: Date.now(),
          })
        }
      })
    },

    checkLocales(value) {
      if (localStorage.lang === 'ru') {
        return value.ru
      }
      return value.uz
    },

    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },

    resetInfoModal() {
      this.infoModal.title = ''
      this.infoModal.content = ''
    },

    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    }

  }

}
</script>

<style lang="scss" scoped>

</style>
